.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

ul.a {
  list-style-position: outside;
}

.image-capture {
  border-radius: 10px;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  /* width: 550;
  height: 350; */
}

.sonia-banner {
  font-family: Manrope;
  font-size: 56px;
  font-weight: 800;
  line-height: 76.5px;
  letter-spacing: -0.01em;
  text-align: left;
}

.loading-bg-video {
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(2.5px);
  -webkit-backdrop-filter: blur(2.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.card-new {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.loginLogo {
  width: 200px !important;
  margin: 10px !important;
}
.row-theme-lightpurple {
  background: linear-gradient(
    90deg,
    rgba(124, 63, 255, 0.1) 0%,
    rgba(75, 38, 153, 0.03) 100%
  );
}
.row-theme-lightorange {
  background: linear-gradient(
    90deg,
    rgba(255, 126, 69, 0.1) 0%,
    rgba(255, 126, 69, 0.05) 100%
  );
}

.row-theme-lightblue {
  background: linear-gradient(
    90deg,
    rgba(18, 148, 242, 0.1) 0%,
    rgba(18, 148, 242, 0.05) 100%
  );
}

.row-theme-lightgreen {
  background: linear-gradient(
    90deg,
    rgba(30, 155, 111, 0.1) 0%,
    rgba(30, 155, 111, 0.05) 100%
  );
}

.thankyou {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  /* margin: 0; */
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: 20px;
}
